(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
/*
================================================================================
* javascript information
* file name  : main.js
================================================================================
*/
$(function(){
  $(window).scroll(function(){
    $('.apng-image').each(function(){
      var imgPos = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      var image_path = $(this).attr("src");
      var anime_path = image_path.replace("images", "anime");
      var webp_path = image_path.replace("images", "anime");
      var webp_extension = webp_path.replace(".png", ".webp");
      if (scroll > imgPos - windowHeight + windowHeight/3){

        if(image_path.indexOf("images")){
          if(window.navigator.userAgent.indexOf('Edge') > 0 || window.navigator.userAgent.indexOf('msie') > 0 || window.navigator.userAgent.indexOf('Android') > 0){
            console.log("ie");
            if(image_path.indexOf('images') != -1){
              $(this).attr("src",anime_path);
              APNG.ifNeeded().then(function() {
                var images = document.querySelectorAll(".for_ie");
                for (var i = 0; i < images.length; i++) APNG.animateImage(images[i]);
              });
              $(this).removeClass("apng-image");
            }

          }else{
            console.log("other");
            $(this).attr("src",anime_path);
            $(this).removeClass("apng-image");

          }

        }else{
        }


      }
    });
  });
});

$(function(){
  $(".nav_btn").click(function(){
    $(".nav_sp ul").slideToggle();
  });
});

$(function(){
  $(".region_parent").each(function(){
    if($(this).next('ul').children().length){
      console.log("ok");
    }else{
      console.log("none");
      $(this).hide();
    }
  });


});

},{}]},{},[1])

